addEventListener("DOMContentLoaded", () => {
  let modals = document.querySelectorAll('.js-modal-open');

  modals.forEach(modal => {
    modal.addEventListener('click', (event) => {
      event.preventDefault();
      const target = event.target.dataset.target;
      const $target = document.getElementById(target);

      window.lintrk('track', { conversion_id: 14760025 });

      if($target) {
        $target.classList.add('modal--open');
      }
    });
  });

  let trackables = document.querySelectorAll('.js-schedule');
  trackables.forEach(track => {
    track.addEventListener('click', () => {
      window.lintrk('track', { conversion_id: 14071972 });
    });
  });

  modals = document.querySelectorAll('.modal');

  modals.forEach(modal => {
    modal.querySelector('.modal__backdrop').addEventListener('click', (event) => {
      event.preventDefault();
      modal.classList.remove('modal--open');
    });

    modal.querySelectorAll('.js-modal-close').forEach( button => {
      button.addEventListener('click', (event) => {
        event.preventDefault();
        modal.classList.remove('modal--open');
      });
    });
  });

  let previousPosition = window.scrollY || document.documentElement.scrollTop;
  let body = document.body, html = document.documentElement;
  let pageHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
  let popupAble = false;
  let popupShown = false;

  window.onscroll = function() {
    var currentPosition = window.scrollY || document.documentElement.scrollTop;

    if (previousPosition > currentPosition && popupAble && !popupShown) {
      //console.log('scrolling up pop-up modal');
      document.getElementById('modal-leaving').classList.add('modal--open');
      popupAble = false;
      popupShown = true;
    } else {
      if((currentPosition / pageHeight) * 100 > 50) { // if they get to > 50%
        popupAble = true;
      }
    }

    previousPosition = currentPosition;
  };

});
